<template>
  <div>
    <l-toggle :FormButton="FormButton">
      <el-form ref="elfrom" :model="searchForm" size="small" label-width="90px">
        <el-row>
          <el-col>
            <l-text-filter
              @refreshTable="refreshTable"
              :filterList="filterList"
              :width="'80px'"
            >
            </l-text-filter>
          </el-col>
        </el-row>
        <el-row type="flex" justify="start">
          <el-col :xs="24" :sm="12" :lg="6">
            <el-form-item label="机型">
              <el-input
                v-model.trim="searchForm.modelName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </l-toggle>
    <div class="tableBox">
      <el-table
        ref="eltable"
        :data="tableData"
        :header-cell-style="{ background: '#f2f2f2' }"
        height="100%"
        stripe
        border
        v-loading="tableLoading"
        @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          <img src="@/assets/img/table_noData.png" width="300" height="300" />
        </template>
        <el-table-column
          type="index"
          width="50"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="modelName"
          label="机型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="basicPrice"
          label="基础价格"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="creatorName"
          label="设置问题人"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="添加时间"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="lastUpdateTime"
          label="最后更新时间"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="当前状态"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{ valueToName(filterDictionary('0101'), scope.row.status) }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" fixed="right" width="400">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit"
              size="mini"
              @click="editHandle(scope.row)"
              >编辑问题
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="delHandle(scope.row)"
              >删除
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="watchHandle(scope.row)"
              >查看
            </el-button>
            <el-button
              v-if="scope.row.isConfig == 0"
              type="warning"
              icon="el-icon-document-copy"
              size="mini"
              @click="select('select-clone-product', scope.row)"
              >克隆
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        background
        :disabled="tableLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[30, 50, 100]"
        :page-size="30"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
      >
      </el-pagination>
      <!-- <div class="tableFootBtn">
        <el-button
          type="danger"
          size="mini"
          icon="el-icon-delete"
          @click="delSelect"
        >删除选择
        </el-button>
      </div> -->
    </div>
    <select-clone-product
      ref="select-clone-product"
      @selected="selectedCloneProduct"
    ></select-clone-product>
  </div>
</template>

<script>
import LToggle from '@/components/LToggle.vue';
import LTextFilter from '@/components/LTextFilter.vue';
import api from '@/api/ValuationItemApi';
import appApi from '@/api/app';
import SelectCloneProduct from '../../components/SelectCloneProduct.vue';

export default {
  name: 'ValuationItem',
  components: {
    LToggle,
    LTextFilter,
    SelectCloneProduct,
  },
  data() {
    return {
      searchForm: {
        modelName: '',
        brandCateId: '',
        firstCateId: '',
      },
      FormButton: [
        {
          icon: 'el-icon-search',
          name: '查询',
          type: 'primary',
          loading: false,
          click: this.searchEvent,
        },
      ],
      tableLoading: false,
      tableData: [],
      selectedTableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 30,
        total: 300,
      },
      firstPro: [],
      secondPro: [],
    };
  },
  watch: {
    tableLoading(nval) {
      this.FormButton[0].loading = nval;
    },
    tableData() {
      this.$refs.eltable.doLayout();
    },
  },
  computed: {
    filterList() {
      const firstPro = this.firstPro.map(val => {
        const obj = {
          name: val.cateName,
          value: val.cateCode,
          id: val.id,
          cateLevel: val.cateLevel,
        };
        return obj;
      });
      const arr = [
        {
          name: '产品大类：',
          data: firstPro,
        },
      ];

      const secondPro = this.secondPro.map(val => {
        const obj = {
          name: val.cateName,
          value: val.cateCode,
          id: val.id,
          cateLevel: val.cateLevel,
        };
        return obj;
      });
      const bigObj = {
        name: '品牌：',
        data: secondPro,
      };
      if (secondPro.length) {
        arr.push(bigObj);
      }
      return arr;
    },
  },
  created() {
    this.reqTableData();
    this.getFirstPro();
  },
  methods: {
    // 获取品牌产品
    getSecondPro(cateLevel, parentCateId) {
      appApi
        .brand({
          cateLevel,
          parentCateId,
        })
        .then(res => {
          this.secondPro = res.result;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取大类产品
    getFirstPro() {
      appApi
        .first()
        .then(res => {
          this.firstPro = res.result;
        })
        .catch(err => {
          console.log(err);
        });
    },
    delSelect() {
      console.log(this.selectedTableData);
      if (this.selectedTableData.length) {
        this.$confirm(
          '确定要<span class="dangerColor">删除所选</span>吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true,
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            dangerouslyUseHTMLString: true,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = '正在删除';
                // api.deleteRole(row.id)
                //   .then(() => {
                //     instance.confirmButtonLoading = false;
                //     this.$message.success('删除成功!');
                //     this.refreshTable();
                //   })
                //   .catch(err => {
                //     this.$message.error(err.message);
                //   })
                //   .finally(() => {
                //     done();
                //   });
              } else {
                done();
              }
            },
          },
        )
          .then(() => {})
          .catch(() => {});
      } else {
        this.$alert('请选择一项！', '提示', { type: 'error' });
      }
    },
    editHandle(row) {
      const routeUrl = this.$router.resolve({
        path: `/ValuationItemEdit/${row.productId}`,
      });
      window.open(routeUrl.href, '_blank');
    },
    delHandle(row) {
      this.$confirm('确定要<span class="dangerColor">删除</span>吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在删除';
            api
              .deleteAssessmentParameter(row.productId)
              .then(() => {
                instance.confirmButtonLoading = false;
                this.$message.success('删除成功!');
                this.refreshTable();
              })
              .catch(err => {
                this.$message.error(err.message);
              })
              .finally(() => {
                done();
              });
          } else {
            done();
          }
        },
      })
        .then(() => {})
        .catch(() => {});
    },
    watchHandle() {},
    searchEvent() {
      this.reqTableData();
    },
    refreshTable(data) {
      // 如果有data，说明是从筛选组件中过来
      if (data) {
        const { tags, self, type } = data;
        // 点击的是筛选文字
        if (type === 1) {
          if (`${self.cateLevel}` === '1') {
            this.getSecondPro(2, self.id);
            this.searchForm.firstCateId = self.id;
          } else if (`${self.cateLevel}` === '2') {
            this.searchForm.brandCateId = self.id;
          }
          // 点击的是关闭标签
        } else if (type === 0) {
          if (tags.length) {
            if (`${tags[0].cateLevel}` === '1') {
              this.getSecondPro(2, tags[0].id);
              this.searchForm.firstCateId = tags[0].id;
              this.searchForm.brandCateId = '';
              this.secondPro = [];
            } else if (`${tags[0].cateLevel}` === '2') {
              this.searchForm.brandCateId = tags[0].id;
              this.searchForm.firstCateId = '';
            }
          } else {
            this.searchForm.firstCateId = '';
            this.searchForm.brandCateId = '';
            this.secondPro = [];
          }
        }
      }
      this.pageInfo.pageNum = 1;
      this.reqTableData();
    },
    reqTableData() {
      this.tableLoading = true;
      const { modelName, firstCateId, brandCateId } = this.searchForm;
      const { pageNum, pageSize } = this.pageInfo;
      api
        .getAssessmentPageList({
          brandCateId,
          firstCateId,
          modelName,
          pageNum,
          pageSize,
        })
        .then(res => {
          this.tableData = res.result.datas;
          this.pageInfo.total = res.result.totalRecords;
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleSelectionChange(select) {
      this.selectedTableData = select;
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.reqTableData();
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.reqTableData();
    },
    select(ref, context) {
      if (ref) {
        this.$refs[ref].open(context);
      }
      return true;
    },
    selectedCloneProduct({ productId: cloneProductId }, { productId }) {
      this.cloneLoading = true;
      const promise = new Promise((resolve, reject) => {
        const ext = {
          productId,
          cloneProductId,
        };
        api
          .cloneAssessment(ext)
          .then(res => {
            this.$message.success('操作成功');
            this.reqTableData();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.cloneLoading = false;
          });
      });
      return promise;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
