<template>
  <el-dialog
    custom-class="table-select-dialog"
    title="选择机型"
    :visible.sync="visible"
    append-to-body
    destroy-on-close
    @opened="getList"
    @closed="closed"
  >
    <dynamic-form :formItems="formItems" :model.sync="model"></dynamic-form>
    <search-reset
      :loading="loading"
      :model.sync="model"
      @search="getList"
    ></search-reset>
    <dynamic-table
      :config="config"
      :loading="loading"
      :columns="columns"
      :tableData="tableData"
      :selectRow.sync="selectRow"
      :pagination.sync="pagination"
      @update="getList"
    ></dynamic-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="confirm" :disabled="!selectRow">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import DynamicForm from './DynamicForm.vue';
import SearchReset from './SearchReset.vue';
import DynamicTable from './DynamicTable.vue';
import api from '../api/ValuationItemApi';

export default {
  name: 'SelectCloneProduct',
  components: {
    DynamicForm,
    SearchReset,
    DynamicTable,
  },
  props: {},
  data() {
    return {
      visible: false,
      model: {},
      formItems: [
        {
          label: '产品大类',
          type: 'select-product-category',
          name: 'firstCateId',
          placeholder: '请选择产品大类',
        },
        {
          label: '品牌',
          type: 'select-brand',
          name: 'brandCateId',
          placeholder: '请选择品牌',
        },
        {
          label: '机型',
          type: 'text',
          name: 'modelName',
          placeholder: '请输入机型',
        },
      ],
      loading: false,
      config: {
        stripe: false,
        highlightCurrentRow: true,
      },
      columns: [
        {
          type: 'index',
          label: '序号',
        },
        {
          label: '产品机型',
          prop: 'modelName',
        },
        {
          label: '基础价格',
          prop: 'basicPrice',
          align: 'right',
        },
        {
          label: '设置问题人',
          prop: 'creatorName',
        },
        {
          label: '添加时间',
          prop: 'createTime',
        },
        {
          label: '最后更新时间',
          prop: 'lastUpdateTime',
        },
        {
          label: '当前状态',
          prop: 'status',
        },
      ],
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      },
      selectRow: null,
      context: null,
    };
  },
  computed: {},
  created() {},
  methods: {
    open(context) {
      this.loading = true;
      this.context = context;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    closed() {
      this.model = {};
      this.tableData = [];
      this.selectRow = null;
      this.pagination = {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      };
      this.loading = false;
    },
    getList() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, pagination } = this;
        const { currentPage, pageSize } = pagination;
        const ext = {
          pageNum: currentPage,
          pageSize,
          ...model,
          isConfig: 1,
        };
        api
          .getAssessmentPageList(ext)
          .then(res => {
            let { datas, totalRecords } = res.result;

            if (
              datas.find(
                ({ productId }) => productId === this.context.productId,
              )
            ) {
              datas = datas.filter(
                ({ productId }) => productId !== this.context.productId,
              );
              totalRecords -= 1;
            }

            this.tableData = datas;
            this.pagination.total = totalRecords;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    confirm() {
      if (!this.selectRow) {
        this.$message.warning('请选择');
        return false;
      }
      this.$emit('selected', this.selectRow, this.context);
      return this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
